<template>
  <div class="err-box">
    <img src="../../../assets/404.png" alt="" />
  </div>
</template>


<script>
export default {
  created() {
    document.title = "404";
    this.$store.commit("updateBarText", "404");
  },
};
</script>

<style lang="less" scoped>
.err-box {
  width: 100%;
  height: calc(100% - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>